import { _mmkv } from './mmkv'
import { MMKVKey } from './keys'

const parseMMKVString = (value?: string) => {
    if (!value) {
        return value
    }

    try {
        return JSON.parse(value)
    } catch {
        return undefined
    }
}

export const mmkvStorage = {
    // getters
    getString: (key: MMKVKey) => _mmkv.getString(key),
    getNumber: (key: MMKVKey) => _mmkv.getNumber(key),
    getBoolean: (key: MMKVKey) => _mmkv.getBoolean(key),
    getObject: <T>(key: MMKVKey) => {
        const value = _mmkv.getString(key)
        const parsedValue = parseMMKVString(value)

        return parsedValue
            ? parsedValue as T
            : parsedValue as T
    },
    getArray: <T>(key: MMKVKey) => {
        const value = _mmkv.getString(key)

        const parsedValue = parseMMKVString(value)

        return parsedValue
            ? parsedValue as T
            : parsedValue as T
    },
    // setters
    setString: (key: MMKVKey, value: string) => _mmkv.set(key, value),
    setNumber: (key: MMKVKey, value: number) => _mmkv.set(key, value),
    setBoolean: (key: MMKVKey, value: boolean) => _mmkv.set(key, value),
    setObject: <T>(key: MMKVKey, value: T) => _mmkv.set(key, JSON.stringify(value)),
    setArray: <T>(key: MMKVKey, value: T) => _mmkv.set(key, JSON.stringify(value)),
    // delete
    delete: (...keys: Array<MMKVKey>) => keys.forEach(key => _mmkv.delete(key)),
    deleteAll: () => _mmkv.clearAll()
}
