import { atom, WritableAtom } from 'jotai'
import { MMKVKey } from 'lib/mmkv'
import { getFromPersistor, getTypeOfAtom, saveToPersistor } from './common'
import { SetAtom, UpdateAtomFunction } from './types'

export const createAtom = <T>(initialState: T, persistanceKey?: MMKVKey) => {
    const atomType = getTypeOfAtom(initialState)
    const initialValue = persistanceKey
        ? getFromPersistor(persistanceKey, atomType) ?? initialState
        : initialState

    const baseAtom = atom(initialValue)
    const persistedAtom = atom(
        get => get(baseAtom),
        (get, set, update) => {
            const nextValue = typeof update === 'function'
                ? (update as UpdateAtomFunction<T>)(get(baseAtom) as T)
                : update

            set(baseAtom, nextValue)

            if (persistanceKey) {
                saveToPersistor<T>(nextValue, persistanceKey, atomType)
            }
        }
    ) as WritableAtom<T, [SetAtom<T>], void>
    const resetAtom = atom(
        null,
        (get, set) => set(persistedAtom, initialState)
    ) as WritableAtom<null, [], undefined>

    return {
        resetAtom,
        atom: persistedAtom
    }
}
