import { MMKVKey, mmkvStorage } from 'lib/mmkv'
import { AtomType } from './types'

export const getTypeOfAtom = <T>(initialState: T): AtomType => {
    const stateType = typeof initialState

    if (stateType !== AtomType.Object) {
        return stateType as AtomType
    }

    if (Array.isArray(initialState)) {
        return AtomType.Array
    }

    if (stateType === AtomType.Object && Boolean(initialState)) {
        return AtomType.Object
    }

    throw new Error(`
        Unsupported atom type!
        Atom type can't be nilable and must be one of: ${Object.values(AtomType).join(', ')}
    `)
}

export const saveToPersistor = <T>(value: T, key: MMKVKey, atomType: AtomType) => {
    switch (atomType) {
        case AtomType.String:
            return mmkvStorage.setString(key, value as string)
        case AtomType.Number:
            return mmkvStorage.setNumber(key, value as number)
        case AtomType.Boolean:
            return mmkvStorage.setBoolean(key, value as boolean)
        case AtomType.Array:
            return mmkvStorage.setArray(key, value)
        case AtomType.Object:
            return mmkvStorage.setObject(key, value)
        default:
            return
    }
}

export const getFromPersistor = (key: MMKVKey, atomType: AtomType) => {
    switch (atomType) {
        case AtomType.String:
            return mmkvStorage.getString(key)
        case AtomType.Number:
            return mmkvStorage.getNumber(key)
        case AtomType.Boolean:
            return mmkvStorage.getBoolean(key)
        case AtomType.Array:
            return mmkvStorage.getArray(key)
        case AtomType.Object:
            return mmkvStorage.getObject(key)
        default:
            return
    }
}
