import React from 'react'
import { Path } from 'react-native-svg'
import { IconProps } from 'lib/types'
import { Icon } from './Icon'

export const Close: React.FunctionComponent<IconProps> = props => (
    <Icon {...props}>
        <Path d="M78.436 2.045a6.042 6.042 0 0 0-8.555 0l-29.67 29.61-29.67-29.67a6.042 6.042 0 0 0-8.555 0 6.042 6.042 0 0 0 0 8.555l29.67 29.67-29.67 29.67a6.042 6.042 0 0 0 0 8.555 6.043 6.043 0 0 0 8.555 0l29.67-29.67 29.67 29.67a6.042 6.042 0 0 0 8.555 0 6.042 6.042 0 0 0 0-8.555l-29.67-29.67 29.67-29.67c2.306-2.306 2.306-6.189 0-8.495Z" />
    </Icon>
)
