import { useWindowDimensions } from 'react-native'
import { Breakpoint, breakpoints, styleUtils } from 'lib/styles'

export const useIsWithinBreakpoints = (from: Breakpoint, to?: Breakpoint) => {
    const dimensions = useWindowDimensions()
    const currentBreakpoint = styleUtils.getBreakpointFromScreenWidth(dimensions.width)
    const allBreakpoints = Object.keys(breakpoints)
    const indexOfCurrentBreakpoint = allBreakpoints.findIndex(breakpoint => breakpoint === currentBreakpoint)
    const indexOfFromBreakpoint = allBreakpoints.findIndex(breakpoint => breakpoint === from)
    const indexOfToBreakpoint = allBreakpoints.findIndex(breakpoint => breakpoint === to)
    const isWithinToBreakpoint = indexOfToBreakpoint === -1 || indexOfCurrentBreakpoint <= indexOfToBreakpoint

    return indexOfCurrentBreakpoint >= indexOfFromBreakpoint && isWithinToBreakpoint
}
