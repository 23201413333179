import { useEffect } from 'react'
import { AppState } from 'react-native'
import { handleFocusManager } from 'lib/api'

export const useQueryFocusManager = () => {
    useEffect(() => {
        const subscription = AppState.addEventListener('change', handleFocusManager)

        return () => subscription.remove()
    }, [])
}
